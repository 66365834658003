import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Grid from '@mui/material/Grid';
import CartIcon from '../../../assets/icons/cart-icon';
import HelpIcon from '../../../assets/icons/help-icon';
import isEmpty from 'lodash/isEmpty';
import { useHeader1 } from './hooks';
import { loggedInUserSelector } from '../../../redux/selectors';
import { Typography } from '@mui/material';
import { logOut } from '../../../utils/auth';
import Notifications from '../notifications/notifications';
import CreateOverrideOrderModal from 'components/modals/create-override-order/create-override-order-modal';
import { PrivateScreen } from 'routes/screen-names';
import CreateOfflineCatalogModal from 'components/modals/create-offline-catalog-modal/create-offline-catalog-modal';
import { useMatch } from 'react-router-dom';
import { useWelcomeBar } from '../welcome-bar/welcomeBarHooks';
import SendInviteEmail from 'components/modals/send-invite-email';
import Share from 'components/share/share';
import { zIndex } from 'constants/zIndex';
import { welcomeBarDataSelector } from 'redux/selectors';
import SearchIcon from 'assets/icons/search-icon';
import LightBulbIconHeader from 'assets/icons/light-bulb-icon-header';
import { useTheme } from '@mui/material/styles';
import DraftsQuickPanelIcon from 'assets/icons/drafts-quick-panel-icon';
import ShippingIcon from 'assets/icons/shipping-icon';
import HeaderCartIcon from 'assets/icons/header-cart-icon';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { responsiveBreakpoints } from 'utils/utils';
import SendShopifyOrderManually from 'components/modals/send-shopify-order-manually/send-shopify-order-manually';

function handleResetScroll() {
  window.scroll(0, 0);
}
function M({
  redirectTo,
  id,
  name,
  hasSubOption,
  subOption,
  showMenuItemsOnScroll,
  onClickLinkedBuyerAccount,
}) {
  let buyerEditRequestExist = false;
  if (id == 'lnk-my-customers-0') {
    {
      subOption?.map((item, i) => {
        if (item.id == 'lnk-my-customers-1') {
          buyerEditRequestExist = true;
        }
      });
    }
  }
  const [showOverrideOrderModal, setShowOverrideOrderModal] = useState(false);
  const [showCreateOfflineCatalogModal, setShowCreateOfflineCatalogModal] =
    useState(false);
  const navigate = useNavigate();
  const user = useSelector(loggedInUserSelector);
  const [showSendInviteEmail, setShowSendInviteEmail] = useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false); // need this state for Fade component
  const [timeoutId, setTimeoutId] = useState(null);
  const [showSendShopifyOrderManually, setShowSendShopifyOrderManually] =
    useState(false);
  const theme = useTheme();
  function showMenu(menuId) {
    setIsPopOverOpen(true);
    if (document.getElementById(menuId))
      document.getElementById(menuId).style.display = 'block';
  }

  function handleScroll() {
    window.scroll(0, document.body.offsetHeight);
    document.getElementById('newpassword').focus();
  }

  function hideMenu(menuId) {
    setIsPopOverOpen(false);
    if (document.getElementById(menuId))
      document.getElementById(menuId).style.display = 'none';
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {/* if id is lft-link-order-dashboard-0 style it for orderpad as per design */}

      {/* override order modal */}
      {showOverrideOrderModal ? (
        <CreateOverrideOrderModal
          open={showOverrideOrderModal}
          setOpen={setShowOverrideOrderModal}
        />
      ) : null}
      {showCreateOfflineCatalogModal ? (
        <CreateOfflineCatalogModal
          open={showCreateOfflineCatalogModal}
          setOpen={setShowCreateOfflineCatalogModal}
        />
      ) : null}
      {showSendInviteEmail ? (
        <Share
          open={showSendInviteEmail}
          setOpen={setShowSendInviteEmail}
          sendinviteEmail={true}
        />
      ) : null}

      {showSendShopifyOrderManually ? (
        <SendShopifyOrderManually
          open={showSendShopifyOrderManually}
          setOpen={setShowSendShopifyOrderManually}
        />
      ) : null}

      {id === 'lft-lnk-order-dashboard-0' ? (
        <Tooltip title='Draft Orders' arrow>
          <Box
            sx={{
              height: '31px',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <IconButton
              variant='contained'
              onClick={() => {
                navigate(redirectTo);
              }}
              id={id}
              onMouseEnter={(e) => {
                setTimeoutId(setTimeout(() => showMenu(`${name}-menu`), 500));
              }}
              onMouseLeave={(e) => {
                setTimeoutId(clearTimeout(timeoutId));
                if (isPopOverOpen) hideMenu(`${name}-menu`);
              }}
              sx={[
                {
                  opacity: 0.9,
                  height: '22px',
                  width: '22px',
                  borderRadius: '50%',
                  color: 'white',
                  padding: '4px',
                  backgroundColor: 'primary.main',
                },
                {
                  '&:hover': {
                    backgroundColor: 'primary.main',
                  },
                },
              ]}
              // sx={[
              //   {
              //     fontWeight: 500,
              //     fontSize: '12px',
              //     padding: '0 10px 0 10px',
              //     position: 'relative',
              //     height: '31px',
              //     borderRadius: 0,
              //   },
              // {
              //   '&:hover': {
              //     backgroundColor: 'primary.main',
              //   },
              // },
              // ]}
            >
              <HeaderCartIcon fill='#ffff' />
              {/* <CartIcon stroke='#ffff' /> */}
              {/* <CartIcon stroke={`${theme.palette.primary.main}`} /> */}
            </IconButton>
          </Box>
        </Tooltip>
      ) : null}

      {/* if id is lft-lnk-help-0 style it for help as per design */}

      {id === 'lft-lnk-help-0' ? (
        <Tooltip title='Help Center' arrow>
          <Box
            sx={{
              height: '31px',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <IconButton
              onClick={() => {
                navigate(PrivateScreen.help);
              }}
              id={id}
              onMouseEnter={(e) => {
                e.preventDefault();
                setTimeoutId(setTimeout(() => showMenu(`${name}-menu`), 500));
              }}
              onMouseLeave={(e) => {
                setTimeoutId(clearTimeout(timeoutId));
                e.preventDefault();
                if (isPopOverOpen) hideMenu(`${name}-menu`);
              }}
              sx={{
                // padding: '10px 10px 10px 10px',
                opacity: 0.9,
                height: '31px',
              }}
            >
              <HelpIcon fill='var(--color-white)' />
            </IconButton>
          </Box>
        </Tooltip>
      ) : null}
      {/* if id is lft-lnk-my-account-0 style it for account as per design */}

      {id === 'lft-lnk-my-account-0' ? (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ height: '31px', width: 'fit-content' }}
        >
          {user.profile_pic ? (
            <Avatar
              src={user.profile_pic}
              sx={{
                width: '28px',
                height: '28px',
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: '28px',
                height: '28px',
                padding: '15px',
              }}
            >
              {user.name
                ? user.name.split(' ')[0]?.charAt(0)?.toUpperCase() +
                  user.name.split(' ')[1]?.charAt(0)?.toUpperCase()
                : null}
            </Avatar>
          )}
          <Button
            variant='text'
            size='small'
            endIcon={<KeyboardArrowDownIcon fill='var(--color-white)' />}
            onClick={() => {
              navigate(redirectTo);
            }}
            id={id}
            onMouseEnter={(e) => {
              setTimeoutId(setTimeout(() => showMenu(`${name}-menu`), 500));
            }}
            onMouseLeave={(e) => {
              setTimeoutId(clearTimeout(timeoutId));
              if (isPopOverOpen) hideMenu(`${name}-menu`);
            }}
            sx={[
              {
                color: 'var(--color-white)',
                opacity: 0.9,
                fontWeight: 500,
                fontSize: '12px',
                padding: '0px 0px 0px 10px',
                height: '100%',
              },
              {
                '.MuiButton-iconSizeSmall': {
                  marginLeft: '2px',
                },
              },
            ]}
          >
            {user.name ? user.name.toUpperCase() : null}
          </Button>
        </Stack>
      ) : null}

      {/* if id is not any of the following ids',  style it normally */}

      {id !== 'lft-lnk-order-dashboard-0' &&
      id !== 'lft-lnk-help-0' &&
      id !== 'lft-lnk-my-account-0' ? (
        <Button
          variant='text'
          onClick={() => {
            navigate(redirectTo);
          }}
          id={id}
          onMouseEnter={(e) => {
            e.preventDefault();
            setTimeoutId(setTimeout(() => showMenu(`${name}-menu`), 500));
          }}
          onMouseLeave={(e) => {
            setTimeoutId(clearTimeout(timeoutId));
            e.preventDefault();
            if (isPopOverOpen) hideMenu(`${name}-menu`);
          }}
          sx={[
            {
              color: 'var(--color-white)',
              opacity: 0.9,
              fontWeight: 500,
              fontSize: '12px',
              padding: '8px 10px 8px 0px',
              height: '100%',
            },
            buyerEditRequestExist && id == 'lnk-my-customers-0'
              ? { color: 'red', fontWeight: 'bold', fontSize: '14px' }
              : null,
          ]}
        >
          {name}
        </Button>
      ) : null}
      {hasSubOption ? (
        <Fade in={isPopOverOpen} timeout={700}>
          <Stack
            boxShadow={3}
            id={`${name}-menu`}
            onMouseEnter={(e) => {
              e.preventDefault();
              showMenu(`${name}-menu`);
            }}
            onMouseLeave={(e) => {
              e.preventDefault();
              hideMenu(`${name}-menu`);
            }}
            sx={{
              display: 'none',
              backgroundColor: 'var(--paper-background-color)',
              // zIndex: 999,
              borderRadius: '6px',
              position: 'absolute',
              top: '31px',
              zIndex: zIndex.popover,
            }}
          >
            {subOption?.map((item, i) => {
              return item.isSection ? (
                <Box
                  key={i}
                  sx={{
                    borderBottom: '1px solid var(--secondary-border-color)',
                    '&:last-of-type': { borderBottom: 0 },
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      hideMenu(`${name}-menu`);
                    }}
                    sx={[
                      {
                        color: 'var(--primary-font-color)',
                        fontWeight: 700,
                        fontSize: '12px',
                        marginTop: '10px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      },
                      {
                        '&:hover': {
                          backgroundColor: 'var(--paper-background-color)',
                        },
                      },
                    ]}
                  >
                    {item?.section?.title?.toUpperCase()}
                  </MenuItem>
                  {item?.section?.subSection?.map((menuItem, i) => {
                    return (
                      <MenuItem
                        key={i}
                        onClick={(e) => {
                          e.preventDefault();
                          hideMenu(`${name}-menu`);
                          if (menuItem?.id == 'link-my-orders-section-1') {
                            sessionStorage.setItem('clearFilters', true);
                          }
                          if (menuItem?.isOtherAccount) {
                            onClickLinkedBuyerAccount({
                              userId: menuItem?.userId,
                            });
                          } else {
                            navigate(menuItem.redirectTo);
                          }
                        }}
                        sx={[
                          {
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            width: '200px',
                          },
                          {
                            '&:last-of-type': {
                              marginBottom: '10px',
                            },
                          },
                        ]}
                      >
                        <Typography variant='body1'>{menuItem.name}</Typography>
                      </MenuItem>
                    );
                  })}
                </Box>
              ) : (
                <MenuItem
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    if (item?.id === 'lnk-offline-catalog-0') {
                      setShowCreateOfflineCatalogModal(true);
                    } else if (item?.id === 'lnk-override-order-0') {
                      setShowOverrideOrderModal(true);
                    } else if (item.id === 'lft-lnk-my-account-2') {
                      window.open(item.redirectTo, '_blank');
                    } else if (item.id === 'lnk-my-invites-0') {
                      // setShowSendInviteEmail(true);
                      navigate(`/send-email/sendInviteEmail`);
                    } else if (item?.name == 'Send Shopify Orders Manually') {
                      setShowSendShopifyOrderManually(true);
                    } else {
                      if (
                        item.redirectTo !== '/account/logout' &&
                        item.id !== 'lnk-offline-catalog-0'
                      ) {
                        navigate(item.redirectTo ? item.redirectTo : '');
                      } else {
                        logOut();
                      }
                    }
                    hideMenu(`${name}-menu`);
                  }}
                  sx={[
                    {
                      color: 'var(--primary-font-color)',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      width: '200px',
                    },
                    {
                      '&:first-of-type': {
                        marginTop: '10px',
                      },
                    },
                    {
                      '&:last-of-type': {
                        marginBottom: '10px',
                      },
                    },
                  ]}
                >
                  {item.name == `Change Password` ? (
                    <Typography variant='body1' onClick={handleScroll}>
                      {item.name}
                    </Typography>
                  ) : (
                    <Typography
                      variant='body1'
                      onClick={handleResetScroll}
                      sx={[
                        {
                          wordWrap: 'break-word', // Breaks words that are too long
                          wordBreak: 'break-all', // Breaks at any character if necessary
                          whiteSpace: 'normal', // Allows text to wrap to the next line
                        },
                        buyerEditRequestExist && item.id == 'lnk-my-customers-1'
                          ? {
                              color: 'red',
                              fontWeight: 'bold',
                              fontSize: '14px',
                            }
                          : null,
                      ]}
                    >
                      {item.name}
                    </Typography>
                  )}
                </MenuItem>
              );
            })}
          </Stack>
        </Fade>
      ) : null}
    </Box>
  );
}

M.propTypes = {
  redirectTo: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  hasSubOption: PropTypes.bool,
  subOption: PropTypes.array,
  showMenuItemsOnScroll: PropTypes.bool,
  onClickLinkedBuyerAccount: PropTypes.func,
};

M.defaultProps = {
  redirectTo: '',
  id: '',
  name: '',
  hasSubOption: '',
  subOption: '',
  showMenuItemsOnScroll: false,
  onClickLinkedBuyerAccount: () => {},
};

function Header1() {
  //flag to check if the app is on a marketplace page
  const isMarketplace = useMatch(`/marketplace/*`);
  const {
    headerMenu,
    showMenuItemsOnScroll,
    activeDraftSuggestion,
    onClickLinkedBuyerAccount,
  } = useHeader1({ isMarketplace });
  const welcomeBarConfig = useSelector(welcomeBarDataSelector);
  const navigate = useNavigate();
  const user = useSelector(loggedInUserSelector);
  const { theme, mobile, tablet, laptop, desktop, mainWidth } =
    responsiveBreakpoints();

  if (!isEmpty(headerMenu))
    return (
      <Box
        position='sticky'
        sx={{
          backgroundColor: '#1a1a1a',
          boxShadow: 'none',
          height: '31px',
          zIndex: zIndex.stickyH1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px',
          top: `${
            !isEmpty(welcomeBarConfig) && welcomeBarConfig.wel_bar_pos == 1
              ? `${welcomeBarConfig.bar_height}px`
              : '0px'
          }`,
        }}
      >
        <Grid container sx={{ height: '100%', maxWidth: mainWidth }}>
          <Grid item xs={8} sx={{ height: '100%' }}>
            <Stack
              direction='row'
              spacing={2}
              onClick={handleResetScroll}
              sx={{ height: '100%' }}
            >
              {headerMenu
                ? headerMenu?.leftHeaderMenu?.map((item, i) => (
                    <M
                      key={i}
                      id={item.id}
                      name={item?.name?.toUpperCase()}
                      redirectTo={item.redirectTo}
                      hasSubOption={item.hasSubOption}
                      subOption={item.subOption}
                    />
                  ))
                : null}
            </Stack>
          </Grid>
          <Grid item xs={4} sx={{ height: '100%' }}>
            <Stack
              direction='row'
              spacing={1}
              sx={{
                height: '100%',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              {headerMenu
                ? headerMenu?.rightHeaderMenu?.map((item, index) => {
                    return (
                      <Stack
                        key={item?.id}
                        sx={
                          index == 1 || index == 0
                            ? {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                              }
                            : {}
                        }
                      >
                        {index === 0 && showMenuItemsOnScroll ? (
                          <Tooltip title='Search' arrow>
                            <Box
                              sx={{
                                height: '31px',
                                alignItems: 'center',
                                display: 'flex',
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  window.scroll(0, 0);
                                }}
                                sx={{
                                  height: '18px',
                                  width: '19px',
                                  opacity: 0.9,
                                }}
                              >
                                <SearchIcon fill='var(--color-white)' />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        ) : null}
                        {index === 0 &&
                        showMenuItemsOnScroll &&
                        user?.roleId !== '3' ? (
                          <Tooltip title='Suggestions' arrow>
                            <Box
                              sx={{
                                height: '31px',
                                alignItems: 'center',
                                display: 'flex',
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  navigate(
                                    `/suggestions/${activeDraftSuggestion?.suggestion?.id}`
                                  );
                                }}
                                sx={{
                                  height: '18px',
                                  width: '19px',
                                  color: 'white',
                                }}
                              >
                                <LightBulbIconHeader
                                  fill='var(--color-white)'
                                  style={{ color: 'white' }}
                                />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        ) : null}
                        <M
                          key={index}
                          id={item?.id}
                          name={item?.name?.toUpperCase()}
                          redirectTo={item?.redirectTo}
                          hasSubOption={item?.hasSubOption}
                          subOption={item?.subOption}
                          showMenuItemsOnScroll={showMenuItemsOnScroll}
                          onClickLinkedBuyerAccount={onClickLinkedBuyerAccount}
                        />
                        {index === 1 && <Notifications />}
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  return <Skeleton variant='rectangular' height='40px' width='100%' />;
}

export default Header1;
