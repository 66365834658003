import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { api } from 'api';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from 'redux/selectors';
import { setOrderSharePdfPayload } from 'pages/send-email/action';
import { useDispatch } from 'react-redux';
import { snackbarTypes } from 'constants/index';
import { showSnackbar } from 'components/snackbar/actions';
import { profileRoles } from 'constants/index';
export default function useAddExternalContact({
  selectedItems,
  setSelectedItems,
}) {
  const [emailData, setEmailData] = useState([]);
  const formikRef = useRef();
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  // state to get input value
  const [emailInput, setEmailInput] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [selectedAssociates, setSelectedAssociates] = useState([]);
  const [editorValue, setEditorValue] = useState([]);
  const [edit, setEdit] = useState(false);
  // send emails
  const [sendingEmails, setSendingEmails] = useState(false);
  const [emailResponses, setEmailResponses] = useState([]);
  const [emailsSent, setEmailsSent] = useState(0);
  const [index, setIndex] = useState(0);
  const [isCopyMeSelected, setIsCopyMeSelected] = useState(false);

  const user = useSelector(loggedInUserSelector);
  const dispatch = useDispatch();
  const onSubmitFormikHandler = (values) => {
    let payload = {
      email: values.email,
    };
    addContacts.mutate(payload);
  };
  const getExternalSalesRepData = async () => {
    const res = await api.get(`getSalesRepExternalContacts`);
    if (res.data.status) {
      setEmailData(res?.data?.data);
      return res?.data?.data;
    }
    return {};
  };
  useEffect(() => {
    getExternalSalesRepData();
  }, []);
  // Add Contacts
  const addContacts = useMutation({
    mutationFn: (payload) => {
      return api.post('addSalesRepExternalContact', payload);
    },
    onSuccess: (data) => {
      if (data?.data?.status) {
        setEmailData((prev) => {
          return [
            {
              id: data?.data?.data?.id,
              contact_email: data?.data?.data?.contact_email,
            },
            ...prev,
          ];
        });
        setSelectedItems((prev) => {
          return [
            ...prev,
            {
              id: data?.data?.data?.id,
              contact_email: data?.data?.data?.contact_email,
            },
          ];
        });
      } else {
        dispatch(
          showSnackbar({
            snackbarMessage: data?.data?.message ?? 'Somthing went wrong',
            type: snackbarTypes.ERROR,
          })
        );
      }
      // getPdfDataQuery.refetch();
    },
  });

  // ======================> Remove Email ID <=========================================
  const deleteEmailQuery = useMutation({
    mutationFn: () => {
      return api.delete('order/removeContacts', {
        data: {
          ids: (() => {
            let updatedArray = [];
            selectedItems.forEach((item) => {
              updatedArray.push(parseInt(item.id));
            });
            return updatedArray;
          })(),
        },
      });
    },
    onSuccess: (data) => {
      //   getPdfDataQuery.refetch();
      setSelectedItems([]);
    },
  });
  return {
    onSubmitFormikHandler,
    addContacts,
    deleteEmailQuery,
    emailData,
    formikRef,
  };
}
