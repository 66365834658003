import React from 'react';
import Page from 'components/page';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from 'assets/icons/download-icon';
// import { suggestionDetails } from 'mockData/product';
import ProductCard from 'pages/suggestions/components/product-card';
import { responsiveBreakpoints } from 'utils/utils';
import useViewSuggestions from './view-suggestions-hooks';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconButton } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import ProductViewToggle from 'pages/home/components/filter-products/product-view-toggle';
import ProductCardVariant from 'pages/catalog/components/product-card-variant';

const ViewSuggestions = () => {
  const {
    id,
    suggestionDetails,
    setSuggestionDetails,
    isSuggestionDetailsLoading,
    productViewStyle,
    setProductViewStyle,
    aspectRatio,
    isImageEditedByClient,
    fetchSuggestions,
  } = useViewSuggestions();
  const downloadPdf = (
    <Button
      size='small'
      variant='outlined'
      color='secondary'
      startIcon={<DownloadIcon />}
      onClick={(e) => {
        fetchSuggestions({ download: true });
      }}
    >
      Download PDF
    </Button>
  );
  let productViewToggleButton = (
    <ProductViewToggle
      productViewStyle={productViewStyle}
      setProductViewStyle={setProductViewStyle}
    />
  );
  const { theme, mobile, tablet, laptop, desktop, mainWidth } =
    responsiveBreakpoints();
  const subHeading = (
    <Stack direction='row' alignItems='center' spacing={1.5} height='18px'>
      <Stack direction='row' spacing={0.5}>
        <Typography
          variant='subtitle1'
          color='inherit'
          sx={{ lineHeight: '18px' }}
        >
          Shared on {suggestionDetails?.created_on} by
        </Typography>
        <Stack direction='row' spacing={0.5}>
          <IconButton>
            <AccountCircleOutlinedIcon
              color='red'
              sx={{ fontSize: '15px', color: '#666666' }}
            />
          </IconButton>{' '}
          <Typography
            variant='subtitle1'
            color='inherit'
            sx={{ lineHeight: '18px' }}
          >
            {suggestionDetails?.created_by}
          </Typography>
        </Stack>
      </Stack>
      {suggestionDetails?.created_user_email ? (
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <FiberManualRecordIcon color='#666666' sx={{ fontSize: '8px' }} />
          <Stack direction='row' spacing={0.5}>
            <IconButton>
              <EmailOutlinedIcon
                fill='#666666'
                sx={{ fontSize: '15px', color: '#666666' }}
              />
            </IconButton>
            <Typography
              variant='subtitle1'
              color='inherit'
              sx={{ lineHeight: '18px' }}
            >
              {suggestionDetails?.created_user_email}
            </Typography>
          </Stack>
        </Stack>
      ) : null}
      {suggestionDetails?.created_user_contact ? (
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <FiberManualRecordIcon color='#666666' sx={{ fontSize: '8px' }} />
          <Stack direction='row' spacing={0.5}>
            <IconButton>
              <PhoneIcon sx={{ fontSize: '15px', color: '#666666' }} />
            </IconButton>
            <Typography
              variant='subtitle1'
              color='inherit'
              sx={{ lineHeight: '18px' }}
            >
              {suggestionDetails?.created_user_contact}
            </Typography>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );

  if (isSuggestionDetailsLoading)
    return (
      <Box
        width='100%'
        height='80vh'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  else
    return (
      <Page
        heading={<span>{suggestionDetails?.suggestion_name}</span>}
        subHeading={subHeading}
        actions={[productViewToggleButton, downloadPdf]}
      >
        <Stack>
          {suggestionDetails?.banner?.src ? (
            <Box
              sx={{
                Height: '346px',
                width: '100%',
                marginBottom: '39px',
                marginTop: '14px',
              }}
            >
              {' '}
              <img
                src={suggestionDetails?.banner?.src}
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null; // prevents looping
                //   currentTarget.src = error_image;
                // }}
                alt=''
                // width='auto'
                style={{
                  objectFit: 'contain',
                  height: '346px',
                  width: 'auto',
                }}
              />
            </Box>
          ) : null}

          {Array.isArray(suggestionDetails?.data) ? (
            suggestionDetails?.data?.map((collection, i) => (
              <Stack
                spacing={2}
                sx={{
                  margin: mobile ? '0 0 30px 0' : '0 0 39px 0',
                  marginTop: !suggestionDetails?.banner?.src ? '10px' : '0px',
                }}
                key={i}
              >
                <>
                  <Typography variant='h2' sx={{ fontWeight: '900' }}>
                    {collection?.collectionName}
                  </Typography>
                  <Grid container>
                    {Array.isArray(collection.products) &&
                      collection?.products?.map((product, i) => (
                        <Grid
                          item
                          key={i}
                          xs={productViewStyle === 'GRID' ? 6 : 12}
                          sm={productViewStyle === 'GRID' ? 6 : 12}
                          md={productViewStyle === 'GRID' ? 2.4 : 12}
                          lg={productViewStyle === 'GRID' ? 2.4 : 12}
                          // sx={[
                          //   mobile
                          //     ? {
                          //         margin: '8px 0 0 0',
                          //         width: '100%',
                          //         display: 'flex',
                          //         alignItems: 'center',
                          //         justifyContent: 'center',
                          //       }
                          //     : {
                          //         margin: '8px 10px 0 0',
                          //       },
                          // ]}
                          sx={{ margin: '10px 0 0 0' }}
                        >
                          {productViewStyle === 'GRID' ? (
                            <ProductCard
                              id={product.id}
                              image={product.image}
                              imageCount={product.imagesCount}
                              name={product.name}
                              style={product.style}
                              cost={product.cost}
                              discountedCost={
                                product.hasDiscount ? product.per_unit_price : 0
                              }
                              msrp={product.msrp}
                              quantity={product.quantity}
                              shortDescription={product.shortDescription}
                              isInCart={false}
                              isInSuggestion={true}
                              //   removeProductFromSuggestion={removeProduct}
                              userCurrencySymbol={product?.currencySymbol}
                              userCurrencyCode={product?.currencyCode}
                              productImageLinkTag={product.productImageLinkTag}
                            />
                          ) : (
                            <ProductCardVariant
                              product={product}
                              ROLE_ID={''}
                              userCurrencySymbol={product?.user_currency_symbol}
                              aspectRatio={aspectRatio}
                              isImageEditedByClient={isImageEditedByClient}
                              showCollectionName={true}
                              isSuggestion={false}
                              removeProductFromSuggestion={() => {}}
                              showSuggestionIcon={false}
                              showAddToCartButton={false}
                            />
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </>
              </Stack>
            ))
          ) : (
            <Stack
              spacing={2}
              justifyContent='center'
              alignItems='center'
              sx={{ height: '200px' }}
            >
              <Typography
                variant='h2'
                sx={{ color: 'red', maxWidth: '600px', textAlign: 'center' }}
              >
                You currently have no items in this suggestion
              </Typography>
              <Typography
                variant='h2'
                sx={{ color: 'red', maxWidth: '600px', textAlign: 'center' }}
              >
                Go back to the catalog & check items to continue or use the
                delete button above to start a new suggestion
              </Typography>
            </Stack>
          )}
        </Stack>
      </Page>
    );
};

export default ViewSuggestions;
